<template lang="pug">
  .app-select-item(
    :key="value"
    :class="{ selected, editing, disabled }"
    :data-cy="dataCy"
    @click.stop="disabled || $emit('select')"
  )
    input.app-select-item-edit-input(
      v-if="editing"
      autofocus
      :ref="refKey"
      v-model="editableTitle"
      @keydown.stop="handleInputKeyUp"
      @click.stop
    )
    .app-select-item-label(
      v-else
      :title="title"
    ) {{ title }}
    .app-select-item-actions(v-if="editable || deletable")
      template(v-if="editing")
        FaIcon(
          icon="undo-alt"
          :title="$t('actions.cancel')"
          @click.stop="editing = false"
        )
        FaIcon(
          icon="check"
          :title="$t('actions.confirm')"
          @click.stop="updateItem"
        )
      template(v-else-if="deleting")
        FaIcon(
          icon="undo-alt"
          :title="$t('actions.cancel')"
          @click.stop="deleting = false"
        )
        FaIcon(
          icon="check-double"
          :title="$t('actions.confirm')"
          @click.stop="deleteItem"
        )
      template(v-else)
        FaIcon(
          v-if="editable"
          icon="pencil-alt"
          @click.stop="startEditing"
        )
        FaIcon(
          v-if="deletable"
          icon="trash-alt"
          @click.stop="deleting = true"
        )
</template>

<script>
  // misc
  import { ESC_KEY_CODE, ENTER_KEY_CODE } from "@/config/constants"

  export default {
    props: {
      value: {
        type: [Number, String],
        required: true
      },
      title: {
        type: [Number, String],
        required: true
      },
      dataCy: {
        type: String
      },
      selected: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      editable: {
        type: Boolean,
        default: false
      },
      deletable: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        editing: false,
        deleting: false,
        editableTitle: ""
      }
    },

    computed: {
      refKey() {
        return `editInput${this.value}`
      }
    },

    methods: {
      handleInputKeyUp({ keyCode }) {
        switch (keyCode) {
          case ESC_KEY_CODE:
            this.editing = false
            break
          case ENTER_KEY_CODE: {
            this.updateItem()
            break
          }
        }
      },

      startEditing() {
        this.editableTitle = this.title
        this.editing = true
        this.$nextTick(() => {
          this.$refs[this.refKey].focus()
        })
      },

      updateItem() {
        this.$emit("update", this.editableTitle)
        this.editing = false
      },

      deleteItem() {
        this.$emit("delete")
        this.deleting = false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .app-select-item
    $item-selector: &

    border: none
    color: $default-black
    cursor: pointer
    display: flex
    background: transparent
    border-radius: 4px
    justify-content: space-between
    text-align: left
    padding: 5px 5px 5px 10px

    &:hover, &:active, &:focus
      border: none !important
      color: $default-black
      outline: none !important
      box-shadow: none !important

    &:hover
      background: transparentize($default-purple-light, 0.5)

    &.editing, &:hover
      #{$item-selector}-actions
        opacity: 1
        display: flex
        max-height: 60%

    &.editing
      padding: 5px

    &:active
      color: $default-black
      background: darken($default-purple-light, 3)

    &.disabled
      color: transparentize($default-black, 0.5)
      cursor: not-allowed

      &:active, &:hover
        background: transparent

    &.selected
      background: $default-purple-light

    &-label
      white-space: nowrap
      overflow-x: hidden
      text-overflow: ellipsis

    &-edit-input
      background: $default-white
      border: 0
      border-radius: 3px
      font-size: 0.8rem
      height: 24px
      width: calc(100% - 48px)

    &-actions
      background: lighten($default-purple-light, 1)
      background: #fbfbff
      border-radius: 3px
      padding: 2px
      display: none
      justify-content: space-around
      align-items: center
      opacity: 0
      transition: opacity 0.25s ease-in-out
      width: 42px

      &> *
        cursor: pointer
        width: 12px
        height: 12px
        margin: 0 2px
</style>
